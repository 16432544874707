import Vue from 'vue'
import VueRouter from 'vue-router'
import SplashScreen from '../views/SplashScreen.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'SplashScreen',
        component: SplashScreen
    },
    {
        path: '/menu',
        name: 'Menu',
        component: () =>
            import ('../views/Menu.vue')
    },
    {
        path: '/sobre',
        name: 'Sobre',
        component: () =>
            import ('../views/Sobre.vue')
    },
    {
        path: '/contato',
        name: 'Contato',
        component: () =>
            import ('../views/Contato.vue')
    },
    {
        path: '/nossos-clientes',
        name: 'NossosClientes',
        component: () =>
            import ('../views/NossosClientes.vue')
    },
    {
        path: '/trabalhos',
        name: 'Trabalhos',
        component: () =>
            import ('../views/Trabalhos.vue')
    },
    {
        path: '/imprensa',
        name: 'Imprensa',
        component: () =>
            import ('../views/Imprensa.vue')
    },
    {
        path: '/trabalhe-conosco',
        name: 'TrabalheConosco',
        component: () =>
            import ('../views/TrabalheConosco.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router