<template>
  <div class="div-body">
    <div id="main-div" class="col div text-center">
     
      <div class="div-logo">
        <img
          class="img-logo"
          src="@/assets/logo-nova.gif"
          alt="Logo MG Cobrança"
        />
      </div>
      <div class="div-person">
        <img class="img-monique" src="@/assets/monique.png" alt="" />
      </div>
     
      <!-- <video
        type="video/mp4"
        ref="video"
        class="vidin"
        src="@/assets/WhatsApp_Video_2022-12-22_at_3.40.25_PM.mp4"
      ></video> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.removeDivs();
  },
  methods: {
    removeDivs() {
      setTimeout(() => {
        var elem = document.getElementById("main-div");

        elem.classList.add("slide-out-right");

        //elem.remove();
        // elem2.remove();
      }, 5000);

      setTimeout(() => {
        var elem = document.getElementById("main-div");

        elem.remove();

        this.$router.push({ name: "Menu" });

        //elem.remove();
        // elem2.remove();
      }, 8000);
    },
  },
};
</script>

<style scoped>
.vidin {
  height: 70vh !important;
  widows: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}

.div-logo {
  width: 20%;
  position: absolute;
  margin-left: 35%;
  margin-top: 5%;
  z-index: 1;
}
.div-person {
  width: 50%;
  position: absolute;
  margin-left: 20%;
}
.img-logo {
  width: 100%;

  /* filter: brightness(0%) invert(100%); */
}

.img-monique {
  width: 100vw;
  height: 100vh;
  padding-right: 400px;
}
@media (max-width: 1200px) {
  .vidin {
    height: auto;
    width: 95vw;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .div-logo {
    width: 20%;
    position: absolute;
    margin-left: 35%;
    margin-top: 5%;
    z-index: 1;
  }
  .div-person {
    width: 50%;
    position: absolute;
    margin-left: 20%;
  }

  .img-logo {
    width: 100%;

    /* filter: brightness(0%) invert(100%); */
  }

  .img-monique {
    width: 100vw;
    height: 100vh;
    padding-right: 400px;
  }
}

@media (max-width: 840px) {
  .div-logo {
    width: 60%;
    position: absolute;
    margin-left: 15%;
    margin-top: 20%;
    z-index: 1;
  }
  .div-person {
    visibility: hidden;
  }

  .img-logo {
    width: 100%;

    /* filter: brightness(0%) invert(100%); */
  }
}

@media (max-width: 450px) {
  .div-logo {
    width: 80%;
    position: absolute;
    margin-left: 0%;
    margin-top: 60%;
    z-index: 1;
  }
  .div-person {
    visibility: hidden;
  }

  .img-logo {
    width: 100%;

    /* filter: brightness(0%) invert(100%); */
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-top {
  -webkit-animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-out-left {
  -webkit-animation: slide-out-left 3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-left 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-out-right {
  -webkit-animation: slide-out-right 3s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-right 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.div {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.div-body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
}
.middle-div {
  background: rgba(255, 255, 255, 0.13);
  width: 5px;
  height: 100vh;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>